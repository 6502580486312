<template>
  <section class="time-line">
    <a-timeline>
      <template v-for="(item, index) in approvalList" :key="index">
        <a-timeline-item color="red" :style="isAdd ? '' : 'height:72px'">
          <template #dot>
            <section class="time-sec1">
              <div class="time-info">
                <div class="time-avatar">
                  <img
                    class="avatar"
                    :src="item.avatarId ? item.fullAvatar : require('@/assets/images/default-avatar.png')"
                  />
                  <i class="iconfont icon-cha1 remove-arrow" v-if="isAdd" @click="$emit('delete-approval', index)" />
                </div>
              </div>
            </section>
          </template>
          <div class="time-sec1">
            <p class="time-name" :title="item.name || item.staffName">{{ item.name || item.staffName }}</p>
          </div>
        </a-timeline-item>
      </template>
      <a-timeline-item v-if="isAdd" color="red" key="ss">
        <template #dot>
          <div class="time-progess" @click="$emit('open-approval-modal')">+</div>
        </template>
      </a-timeline-item>
    </a-timeline>
  </section>
</template>

<script>
import { approvalStatusObj, archiveStatusObj } from '@/utils/constData'
export default {
  name: 'approval-staff-list',
  props: {
    approvalList: {
      type: Array,
      default: () => [],
    },
    isAdd: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      approvalStatusObj,
      archiveStatusObj,
    }
  },
}
</script>

<style lang="less" scoped>
:deep(.ant-timeline-item) {
  padding-bottom: 32px;
}
:deep(.ant-timeline-item-content) {
  margin-left: 60px;
  top: 0;
}
.time-line {
  max-height: 220px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: block;
    border-radius: 5px;
    background: #f5f5f5;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px;
    background: #c6c6c6;
    border-radius: 2px;
  }
}
.time-progess {
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e9e9e9;
  font-size: 18px;
  padding: 0;
  color: #666666;
  cursor: pointer;
}
.time-sec1 {
  // margin-bottom: 10px;
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  min-height: 40px;
  .time-figure {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    margin-bottom: 2px;
    display: block;
  }
  .time-info {
    display: flex;
    align-items: center;
    .time-avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 16px;
      position: relative;
      .avatar {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
      .remove-arrow {
        position: absolute;
        top: -2px;
        right: 0px;
        color: #c3161c;
        font-size: 12px;
        background: #ffffff;
        height: 12px;
        line-height: 12px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
  .time-name {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: rgba(0, 0, 0, 0.65);
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 40px;
  }
}
:deep(.ant-timeline-item-tail) {
  border-left: 3px solid #c3161c;
  top: 48px;
  left: 18px;
  height: 24px;
}
.ant-timeline-item-last {
  padding-bottom: 0;
  > .ant-timeline-item-tail {
    border-left: none;
  }
}
:deep(.ant-timeline-item-head-custom) {
  padding: 0;
  transform: translate(0, 0);
  left: 0;
  top: 0;
  margin: 4px 0;
}
</style>
